export const EXPERIMENT_NO_ADS = "noAds";
export const EXPERIMENT_TEXAS_SLATE = "texasSlate";
export const EXPERIMENT_FULLSCREEN_RECOMBEE = "005_recombee-full-screen-experiment";
export const EXPERIMENT_RECOMBEE_TRENDING_FEED = "003_recombee-trending-experiment";

export const VARIANT_FS_RECOMBEE = "full-screen-recombee";
export const VARIANT_NON_FS_NON_RECOMBEE = "non-full-screen-non-recombee";
export const VARIANT_FS_RECOMBEE_FILTERED = "full-screen-recombee-filtered";

export const VARIANT_CONTROL = "control";
export const VARIANT_TREATMENT = "treatment";
const VARIANT_OFF = "off";

export const EXPERIMENT_COVERAGE_MAP: {[key: string]: number} = {
  [EXPERIMENT_FULLSCREEN_RECOMBEE]: 0.27,
  [EXPERIMENT_RECOMBEE_TRENDING_FEED]: 0.27
};

export const FULLSCREEN_RECOMBEE_VARIANT_LIST = [
  {
    label: VARIANT_NON_FS_NON_RECOMBEE,
    value: VARIANT_NON_FS_NON_RECOMBEE
  },
  {
    label: VARIANT_FS_RECOMBEE,
    value: VARIANT_FS_RECOMBEE
  },
  {
    label: VARIANT_FS_RECOMBEE_FILTERED,
    value: VARIANT_FS_RECOMBEE_FILTERED
  }
];

export const RECOMBEE_TRENDING_FEED_VARIANT_LIST = [
  {
    label: VARIANT_TREATMENT,
    value: VARIANT_TREATMENT
  },
  {
    label: VARIANT_CONTROL,
    value: VARIANT_CONTROL
  },
  {
    label: VARIANT_OFF,
    value: VARIANT_OFF
  },
];
