import { metricsBus } from "@/common/buses";
import amplitudeAnalytics from "@/common/services/amplitude/amplitudeAnalytics";
import seedrandom from "seedrandom";
import { loadAmplitudeSessionId, setAmplitudeSessionId } from "@/common/utils/storage";
import { AmplitudeReturn, Result } from "@amplitude/analytics-types";

const isFullscreen = () => {
  return !!document.querySelector("#root")?.classList?.contains("fullScreen");
};

const isWatchPage = () => window.location.pathname.startsWith("/watch/");
const isHomePage = () => window.location.pathname === "/";
const trackPageVisit = async (sendTrack: (isLanding: boolean) => Promise<AmplitudeReturn<Result> | undefined>) => {
  const lastSessionId = loadAmplitudeSessionId();
  const currentSessionId = await amplitudeAnalytics.getSessionId();

  const isLanding = !lastSessionId || lastSessionId !== currentSessionId;
  if (isLanding) {
    setAmplitudeSessionId(currentSessionId);
  }

  return sendTrack(isLanding);
};

metricsBus.on("forceFullscreenEntered",() => amplitudeAnalytics.sendForcedFullscreenEntered());

metricsBus.on("forceFullscreenExited", () => amplitudeAnalytics.sendForcedFullscreenExitClicked());

metricsBus.on("uploadStarted", () => amplitudeAnalytics.sendUploadStarted());

metricsBus.on("uploadTagSelectionStarted", () => amplitudeAnalytics.sendUploadTagSelectionStarted());

metricsBus.on("uploadTagSelectionEnded", ({ count }) => amplitudeAnalytics.sendUploadTagSelectionEnded(count));

metricsBus.on("uploadEnded", () => amplitudeAnalytics.sendUploadEnded());

metricsBus.on("watchPageVisited", () => {
  return trackPageVisit((isLanding) => amplitudeAnalytics.sendWatchPageVisited(isLanding));
});

metricsBus.on("gifView", ({ gifId }) => {
  if (isWatchPage() || isHomePage()) {
    return amplitudeAnalytics.sendGifViewed(gifId, isFullscreen());
  }
});

metricsBus.on("meaningfulGifView", ({ gifId }) => {
  if (isWatchPage() || isHomePage()) {
    return amplitudeAnalytics.sendGifViewedMeaningful(gifId, isFullscreen());
  }
});

metricsBus.on("gifLiked", ({ gifId, isMainGIF }) => {
  if (isWatchPage() || isHomePage()) {
    return amplitudeAnalytics.sendGifLiked(gifId, isFullscreen(), isWatchPage() ? isMainGIF : undefined);
  }
});

metricsBus.on("gifUnliked", ({ gifId, isMainGIF }) => {
  if (isWatchPage() || isHomePage()) {
    return amplitudeAnalytics.sendGifUnliked(gifId, isFullscreen(), isWatchPage() ? isMainGIF : undefined);
  }
});

metricsBus.on("addedToCollection", ({ gifId }) => {
  if (isWatchPage() || isHomePage()) {
    return amplitudeAnalytics.sendGifAddedToCollection(gifId, isFullscreen());
  }
});

metricsBus.on("fullscreenEntered", () => amplitudeAnalytics.sendFullscreenEntered());

metricsBus.on("fullscreenExited", () => amplitudeAnalytics.sendFullscreenExited());

metricsBus.on("menuButtonClicked", ({ label }) => amplitudeAnalytics.sendNavButtonClicked(label));

metricsBus.on("searchBarOpened", () => amplitudeAnalytics.sendSearchBarClicked());

metricsBus.on("searched", ({ query }) => amplitudeAnalytics.sendSearchQuerySubmitted(query));

metricsBus.on("loginCompleted", () => amplitudeAnalytics.sendLoginCompleted());

metricsBus.on("userFetched", ({ user }) => amplitudeAnalytics.identifyUser(user));

metricsBus.on("homePageVisited", () => {
  return trackPageVisit((isLanding) => amplitudeAnalytics.sendHomePageVisited(isLanding));
});

metricsBus.on("deviceTracked", ({ deviceId, deviceType }) => {
  const getDeviceScore = seedrandom(deviceId);

  amplitudeAnalytics.setDeviceId(deviceId);
  amplitudeAnalytics.updateUserProperties({
    "Device Score": getDeviceScore(),
    "Device Type": deviceType,
  });
});

metricsBus.on("experimentVariantExposure", ({ experimentKey, experimentVariant }) => {
  amplitudeAnalytics.updateUserProperties({ [experimentKey]: experimentVariant });
});

metricsBus.on("firstPageVisited", ({ path }) => {
  if (path.startsWith("/watch/")) {
    amplitudeAnalytics.updateUserProperties({
      "Landed to Watch Page": true
    });
  }
});

