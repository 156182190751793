import { PreferencesPopup } from "components/UI/Popups/PreferencesPopup";
import { ChangedEmailPopup } from "components/UI/Popups/ChangedEmailPopup";
import { AddToNichePopup } from "components/niches/AddToNichePopup";
import { ReportPopup } from "components/UI/Popups/Report";
import { AddToCollectionPopup } from "components/UI/Popups/Collections";
import { EditNichePopup } from "components/popups/EditNichePopup";
import { EditNichesDialog } from "components/dialogs/EditNichesDialog";
import { GifPreferencePopup } from "components/UI/Popups/GifPreferencePopup";
import { ShareGifMenu } from "components/UI/ShareMenu";
import { MoreMenu } from "components/popups/MoreMenu";
import { NicheMoreMenu } from "components/popups/NicheMoreMenu";
import { CookieNoticeWrapper } from "components/widgets/CookieNoticeWrapper";
import { EditGifPopup } from "components/popups/EditGifPopup";
import { AddToNicheSuccessPopup } from "components/niches/AddToNicheSuccessPopup";
import { AddDescriptionPopup } from "components/popups/AddDescriptionPopup";
import { AddToNicheConfirm } from "components/popups/AddToNicheConfirm";

export enum PopupsEnum {
  Preferences = "preferences",
  ChangedEmail = "changedEmail",
  AddNiche = "addNiche",
  Report = "report",
  AddCollection = "addCollection",
  EditNiche = "editNiche",
  EditGifNiches = "editGifNiches",
  EditGifPreferences = "editGifPreferences",
  ShareGif = "shareGif",
  MoreMenu = "moreMenu",
  CookieNotice = "cookieNotice",
  EditGif = "editGif",
  NicheMoreMenu= "nicheMoreMenu",
  AddToNicheSuccess= "addToNicheSuccess",
  AddDescriptionPopup= "addDescriptionPopup",
  AddToNicheConfirm= "addToNicheConfirm",
}

export const popups = {
  [PopupsEnum.Preferences]: PreferencesPopup,
  [PopupsEnum.ChangedEmail]: ChangedEmailPopup,
  [PopupsEnum.AddNiche]: AddToNichePopup,
  [PopupsEnum.Report]: ReportPopup,
  [PopupsEnum.AddCollection]: AddToCollectionPopup,
  [PopupsEnum.EditNiche]: EditNichePopup,
  [PopupsEnum.EditGifNiches]: EditNichesDialog,
  [PopupsEnum.EditGifPreferences]: GifPreferencePopup,
  [PopupsEnum.ShareGif]: ShareGifMenu,
  [PopupsEnum.MoreMenu]: MoreMenu,
  [PopupsEnum.CookieNotice]: CookieNoticeWrapper,
  [PopupsEnum.EditGif]: EditGifPopup,
  [PopupsEnum.NicheMoreMenu]: NicheMoreMenu,
  [PopupsEnum.AddToNicheSuccess]: AddToNicheSuccessPopup,
  [PopupsEnum.AddDescriptionPopup]: AddDescriptionPopup,
  [PopupsEnum.AddToNicheConfirm]: AddToNicheConfirm,
};
